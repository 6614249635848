<template>
  <v-container class="grey lighten-5">
    <v-row
        class="mb-6"
        no-gutters
    >
      <v-col
          :cols="4"
      >
        <v-card
            class="pa-2"
            tile
            outlined color="transparent"
            style="font-size: 10px;"
        >
          <v-img
              :width="158"
              src="/emclogo.png"
          ></v-img>
          <br>
          <b>Electrical Mechanical & Cooling Ltd</b><br>
          2 Ellerbeck Ct, Stokesley Business Park,<br>
          Stokesley, North Yorkshire, TS9 5PT<br>
          <b>Email:</b> sarah.williams@emcltd.co<br>
          <b>Website:</b> www.emcltd.co
        </v-card>
      </v-col>
      <v-col
          :cols="4"
      >
        <v-card
            class="pa-2 text-center"
            tile
            outlined color="transparent"
        >
          <h1>Service Work Record</h1>
          <v-img
              :width="376"
              src="/emc-accreds.png"
          ></v-img>
        </v-card>
      </v-col>
      <v-col
          :cols="4"
      >
        <v-card
            class="pa-2"
            tile
            outlined color="transparent"
            align="right"
        >
          <b>Job Number: </b>{{jobSheet.jobNumber}}<br>
          <b>Engineer:</b> {{jobSheet.engineer}}<br>
          <b>Work Date:</b> {{jobSheet.workDate}}<br><br>
          <b>Site Address:</b><br>
          {{jobSheet.metadata.siteAddress}}
          <br />

        </v-card>
      </v-col>
    </v-row>
    <v-row
        class="mb-6"
        no-gutters
    >
      <v-col
          :cols="12"
      >
        <v-card
            class="pa-2"
            tile
            outlined color="transparent"
        >
        <b>Customer:</b> {{jobSheet.customerName}}
        </v-card>
      </v-col>
    </v-row>
    <v-row
        class="mb-6"
        no-gutters>
      <v-col
          :cols="12"
      >
      <v-simple-table
      >
        <template v-slot:default>
          <thead>
          <tr>
            <th class="text-left">
              Make
            </th>
            <th class="text-left">
              Model
            </th>
            <th class="text-left">
              Serial No
            </th>
            <th class="text-left">
              KVA
            </th>
            <th class="text-left">
              Battery
            </th>
            <th class="text-left">
              Date Code
            </th>
            <th class="text-left">
              Strings & Qty
            </th>
          </tr>
          </thead>
          <tbody>
          <tr
              v-for="equipment in jobSheet.metadata.equipmentList"
              :key="equipment.name"
          >
            <td>{{ equipment.equipmentMake }}</td>
            <td>{{ equipment.equipmentModel }}</td>
            <td>{{ equipment.equipmentSerialNo }}</td>
            <td>{{ equipment.equipmentKVA }}</td>
            <td>{{ equipment.equipmentBattery }}</td>
            <td>{{ equipment.equipmentDateCode }}</td>
            <td>{{ equipment.equipmentStringsQty }}</td>
          </tr>
          </tbody>
        </template>
      </v-simple-table>
      </v-col>
    </v-row>
    <v-row
        class="mb-6"
        no-gutters
    >
      <v-col
          :cols="12"
      >
        <v-card
            class="pa-2"
            tile
            outlined color="transparent"
        >
          <b>Purpose of Works: </b>{{jobSheet.metadata.purposeOfWorks.replaceAll(' ', ' - ')}}
        </v-card>
      </v-col>
    </v-row>
    <v-row
        class="mb-6"
        no-gutters
    >
      <v-col
          :cols="12"
      >
        <v-card
            class="pa-2"
            tile
            outlined color="transparent"
        >
          <b>Work Carried Out:</b>
          <pre>{{jobSheet.metadata.workCarriedOut}}</pre>
        </v-card>
      </v-col>
    </v-row>
    <v-row
        class="mb-6"
        no-gutters
    >
      <v-col
          :cols="12"
      >
        <v-card
            class="pa-2"
            tile
            outlined color="transparent"
        >
          <b>Outstanding Requirements:</b>
          <pre>{{jobSheet.metadata.outstandingRequirements}}</pre>
        </v-card>
      </v-col>
    </v-row>
    <v-row
        class="mb-6"
        no-gutters>
      <v-col
          :cols="12"
      >
        <v-simple-table
        >
          <template v-slot:default>
            <thead>
            <tr>
              <th class="text-left">
                Date
              </th>
              <th class="text-left">
                Travel Start
              </th>
              <th class="text-left">
                Work Start
              </th>
              <th class="text-left">
                Work End
              </th>
              <th class="text-left">
                Travel End
              </th>
              <th class="text-left">
                Engineer
              </th>
              <th class="text-left">
                Signature
              </th>
            </tr>
            </thead>
            <tbody>
            <tr
                v-for="work in jobSheet.metadata.workLog"
                :key="work.name"
            >
              <td>{{ work.workLogDate }}</td>
              <td>{{ work.workLogTravelStart.substring(0,5) }}</td>
              <td>{{ work.workLogWorkStart.substring(0,5) }}</td>
              <td>{{ work.workLogWorkEnd.substring(0,5) }}</td>
              <td>{{ work.workLogTravelFinish.substring(0,5) }}</td>
              <td>{{ work.workLogEngineerName.substring(0,5) }}</td>

              <td><v-img width="90px" :src="'/api/attachments/'+jobSheet.metadata.instanceID.replace('uuid:', '')+'/workLogSignature-'+work.workLogSignature.filename"></v-img></td>
            </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-col>
    </v-row>

    <v-row
        class="mb-6"
        no-gutters
    >
      <v-col
          :cols="6"
      >
        <v-card
            class="pa-2"
            tile
            outlined color="transparent"
        >
          <b>Job Completed:</b> {{ jobSheet.metadata.jobCompleted === '1' ? 'Yes' : 'No' }}
        </v-card>
      </v-col>
      <v-col
          :cols="6"
      >
        <v-card
            class="pa-2"
            tile
            outlined color="transparent"
        >
          <b>Further Visit Required:</b> {{ jobSheet.metadata.furtherVisitRequired === '1' ? 'Yes' : 'No' }}
        </v-card>
      </v-col>

    </v-row>


    <v-row
        class="mb-6"
        no-gutters
    >
      <v-col
          :cols="4"
      >
        <v-card
            class="pa-2"
            tile
            outlined color="transparent"
        >
          <b>Customer Name:</b> {{jobSheet.metadata.jobSheetCustomerSignoffName}}<br />
          <b>Customer Company:</b> {{jobSheet.metadata.jobSheetSignoffCustomerCompanyName}}<br />
        </v-card>
      </v-col>
      <v-col
          :cols="4"
      >
        <v-card
            class="pa-2"
            tile
            outlined color="transparent"
        >
          <v-img width="500px" :src="'/api/attachments/'+jobSheet.metadata.instanceID.replace('uuid:', '')+'/jobSheetCustomerSignoffSignature-'+jobSheet.metadata.jobSheetCustomerSignoffSignature.filename"></v-img>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col :cols="12">
        <v-carousel v-model="model" height="700">
          <v-carousel-item
              v-for="(photo, i) in jobSheet.metadata.workRecordImages"
              :key="i"
              :src="'/api/attachments/'+jobSheet.metadata.instanceID.replace('uuid:', '')+'/'+photo.workRecordImageFile.assetFilename"
              reverse-transition="fade-transition"
              transition="fade-transition"
          >
          </v-carousel-item>
        </v-carousel>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
export default {
  metaInfo: {
    // title will be injected into parent titleTemplate
    title: 'View Job Sheet'
  },
  data() {
    return {
      jobSheet: {
      },
      tab: 0,
      model: 0,
      photoProps: [
        'controlConnectionPointPhoto',
        'powerConnectionPointPhoto',
        'coldSensorPositionPhoto',
        'returnAirSensorPositionPhoto',
        'generalInstallationPhoto',
        'photos2Installation',
        'photos2ThermostatController'
      ],
    }
  },
  computed: {
  },
  methods: {
    getDevice() {
      if (this.$route.params.id !== null) {
        this.$store.dispatch('job-sheets/get', this.$route.params.id).then((result) => {
              this.jobSheet = result;
            }
        );
      }
    }
  },
  mounted() {
    this.getDevice();
  }
}
</script>
<style>
dl {
  padding: 0.5em;
}
dt {
  float: left;
  clear: left;
  width: 400px;
  text-align: right;
  font-weight: bold;
  color: green;
}
dt::after {
  content: ": ";
}
dd {
  margin: 0 0 0 110px;
  padding: 0 0 0.7em 0;
}
tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, .05);
}
</style>
